import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про компанію | Оренда яхт Olena
			</title>
			<meta name={"description"} content={"Підняти вітрила на розкішному лайнері"} />
			<meta property={"og:title"} content={"Про компанію | Оренда яхт Olena"} />
			<meta property={"og:description"} content={"Підняти вітрила на розкішному лайнері"} />
			<meta property={"og:image"} content={"https://stellarinfors.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://stellarinfors.com/img/57592346.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://stellarinfors.com/img/57592346.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://stellarinfors.com/img/57592346.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://stellarinfors.com/img/57592346.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://stellarinfors.com/img/57592346.png"} />
			<meta name={"msapplication-TileImage"} content={"https://stellarinfors.com/img/57592346.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://stellarinfors.com/img/5.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="space-between"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						font="--base"
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--grey"
						letter-spacing="2px"
					>
						Про Нас
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наша історія
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					Відчуйте надзвичайне на морі
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-5">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 36px 00px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="30%"
					lg-width="100%"
					lg-margin="24px 0px 30px 0px"
					lg-order="1"
					md-text-align="left"
				>
					Оренда яхт Olena запрошує вас у світ ексклюзивних яхтових послуг, створених для неперевершеної розкоші та комфорту. Наш широкий спектр послуг гарантує, що кожен аспект вашої морської подорожі буде охоплений, дозволяючи вам насолодитися бездоганним і вишуканим досвідом плавання під вітрилами.
				</Text>
				<Box position="relative" padding="0px" width="100%">
					<Image
						src="https://stellarinfors.com/img/6.jpg"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						display="block"
						width="100%"
						left={0}
					/>
				</Box>
			</Box>
			<Box
				justify-content="center"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="45%"
				lg-width="100%"
				align-items="flex-start"
			>
				<Box
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					position="relative"
					padding="0px"
				>
					<Image object-fit="cover" display="block" src="https://stellarinfors.com/img/7.jpg" width="100%" />
				</Box>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="55%"
				lg-order="1"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="100%" height="auto" padding="0px">
					<Image
						src="https://stellarinfors.com/img/8.jpg"
						object-fit="cover"
						min-height="100%"
						display="block"
						width="100%"
					/>
				</Box>
				<Text
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Наш флот, серце Olena, складається з різноманітних ультрасучасних яхт, кожна з яких обладнана відповідно до найвищих стандартів розкоші та продуктивності. Ми пропонуємо від витончених вітрильників до величних моторних яхт.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 180px 0" lg-padding="90px 0 90px 0" sm-padding="45px 0 60px 0" quarkly-title="About-15">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="280px" />
			<Box
				width="40%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				justify-content="space-between"
				lg-width="100%"
				lg-align-items="stretch"
				lg-flex-direction="row"
				lg-justify-content="flex-start"
				md-flex-direction="column"
			>
				<Box
					lg-width="80%"
					lg-display="flex"
					lg-flex-direction="column"
					lg-align-items="flex-start"
					lg-justify-content="flex-end"
					md-width="100%"
					height="100%"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="space-around"
				>
					<Text
						margin="0px 0px 24px 0px"
						font="--headline2"
						color="--dark"
						lg-text-align="left"
						md-padding="0px 0px 0px 0px"
					>
						Забронюйте розкішну подорож на яхті зараз
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						font="--base"
						color="--greyD2"
						md-text-align="left"
						lg-flex="1 1 0%"
						lg-padding="0px 0px 0px 0px"
						lg-margin="0px 0px 32px 0px"
						sm-margin="0px 0px 28px 0px"
					>
						Відчуйте морську чарівність з Olena Yacht Charters. Зв'яжіться з нами, щоб дізнатися більше про наші послуги і почати планувати свою подорож по хвилях. Кожна подорож з нами - це крок до незабутніх спогадів. Відчуйте вершину морської розкоші. Нехай горизонт буде вашим провідником.
					</Text>
					<Link
						href="/contacts"
						color="--lightD1"
						text-decoration-line="initial"
						padding="7px 24px 8px 24px"
						background="--color-primary"
						font="--base"
						transition="--opacityOut"
						hover-opacity=".7"
						letter-spacing="1px"
						border-radius="4px"
					>
						Відправитись у подорож
					</Link>
				</Box>
			</Box>
			<Box
				width="60%"
				display="flex"
				justify-content="flex-end"
				lg-width="100%"
				lg-margin="48px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Box position="relative">
					<Image
						src="https://stellarinfors.com/img/9.jpg"
						display="block"
						object-fit="cover"
						height="380px"
						width="550px"
						lg-width="100%"
						lg-height="auto"
						lg-margin="8px 0px 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});